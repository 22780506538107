// const BASE_URL = "http://192.168.21.2:5001/node_api/";
//demo
const BASE_URL = "https://istvan.admin.iossmlm.com/node_api/";


//live
// const BASE_URL = "https://admin.flowinternationalgroup.com/node_api/";

const DEFAULT_KEY = 'fd20ef88-4702-4da5-9935-0780d6a0cfe0'
//  test

const PAYPAL_CLIENT_ID = "Ad077-Gx8stGcfWQYawBiQUc4UbKWIRIg_PK4lHL4pzHR-uOPMz2vCk_jQ8QU9pcEw0j8U3UYDrEUS75"

export { BASE_URL, DEFAULT_KEY, PAYPAL_CLIENT_ID }
